import { useEffect } from "react";

import SEO from "seo/SEO";
import { LoginPage } from "templates";
import { scrollToTop } from "utils/utilities";

const Login = () => {

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title="Login | Photonence Admin"
        description="Login | Photonence Admin"        
      />
      <LoginPage/> 
    </>
  )
}

export default Login