import { combineReducers } from "redux";

import { appReducer } from "./app.reducer";
import { userReducer } from "./user.reducer";
import { providerReducer } from "./provider.reducer";

const rootReducer = combineReducers({
  app: appReducer,    
  user: userReducer,  
  provider: providerReducer,
});

export default rootReducer;