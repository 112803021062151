import React, { useEffect } from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import NotFoundAnimation from "assets/animations/not-found.json";
import { Box, Button } from "atoms";
import { scrollToTop } from "utils/utilities";

export default function NotFound() {

  document.title = "404 | Page Not Found"

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <Box>
        <Box          
          borderRadius="0.4rem"
          height="auto"
          bg="white"          
        >
          <Box
            textAlign="center"
            width={{ xs: "32rem", md: "60rem" }}
            height={{ xs: "30rem", md: "60rem" }}            
            m="auto"
          >
            <Player autoplay loop src={NotFoundAnimation}>
              <Controls visible={false} />
            </Player>
          </Box>          
          <Button
            mb="2rem"
            variant="primary"
            onClick={() => window.history.back()}
            p="1rem 2rem"
            width="fit-content"
            borderRadius="0.4rem"
            mx="auto"
          >
            Go Back
          </Button>          
        </Box>
      </Box>
    </>
  );
}