import React, { useCallback, useState } from "react";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import { variant } from "styled-system";
import PropTypes from "prop-types";

import { Box, Flex, Text } from "atoms";

const ButtonBase = styled(Box)`
  position: relative;
  cursor: pointer;
  outline: none;
  border: none;
  display: grid;
  align-items: center;  
  transition: color ease-in-out 400ms;
  font-size: 1.6rem;

  ${variant({
    variants: {
      primary: {
        color: "white",        
        bg: "primary.100",                                        
        "&:active, &:hover": {
          bg: "primary.500", 
          outline: "none",           
        },
        "&:focus": {
          bg: "primary.500", 
          outline: "none",           
        },
      },      
      secondary: {
        color: "white",
        bg: "secondary.100",                
        "&:active, &:hover": {
          bg: "secondary.500",          
          outline: "none",  
        },
        "&:focus": {
          bg: "secondary.500",   
          outline: "none",         
        },
      },
      accent: {
        color: "white",
        bg: "accent.100",                
        "&:active, &:hover": {
          bg: "accent.500",   
          outline: "none",         
        },
        "&:focus": {
          bg: "accent.500", 
          outline: "none",           
        },
      },
      white: {
        color: "white",        
        bg: "transparent",
        border: "1px solid white",                                        
        "&:active, &:hover": {
          bg: "white", 
          color: "black",
          outline: "none",                     
        },
        "&:focus": {
          bg: "white", 
          color: "black",
          outline: "none",                     
        },
      },      
      success: {
        color: "white",
        bg: "success.100",                
        "&:active, &:hover": {
          bg: "success.500",          
          outline: "none",  
        },
        "&:focus": {
          bg: "success.500",          
          outline: "none",          
        },
      },
      info: {
        color: "white",
        bg: "info.100",                
        "&:active, &:hover": {
          bg: "info.500",          
          outline: "none",          
        },
        "&:focus": {
          bg: "info.500",          
          outline: "none",          
        },
      },
      danger: {
        color: "white",
        bg: "danger.100",                
        "&:active, &:hover": {
          bg: "danger.500",
          outline: "none",          
        },
        "&:focus": {
          bg: "danger.500",          
          outline: "none",          
        },
      },
      warning: {
        color: "white",
        bg: "warning.100",                
        "&:active, &:hover": {
          bg: "warning.500",
          outline: "none",          
        },
        "&:focus": {
          bg: "warning.500",          
          outline: "none",          
        },
      },
      disabled: {
        color: "white",
        backgroundColor: "grey.300",
        cursor: "not-allowed",
        border: "none",
      },      
    },
  })}
  &[disabled] {
    color: "black";
    background-color: "grey.300";
    cursor: not-allowed;
  }
`;

export const Button = ({
  disabled,
  loading,
  children,
  variant: v = "primary",
  fontSize,
  onClick,
  showAsyncLoad,
  fontWeight,
  classes,
  label,
  ...rest
}) => {
  const [asyncLoading, setLoading] = useState(false);
  const onClickHandler = useCallback(
    async (...arg) => {
      setLoading(true);
      try {
        await onClick?.(arg);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [onClick]
  );

  return (
    <ButtonBase
      variant={v}
      as="button"
      color="white"
      {...rest}
      onClick={onClickHandler}
      disabled={disabled || loading || (showAsyncLoad && asyncLoading)}
      overflow="hidden"
      className={classes}
    >
      {(loading || (showAsyncLoad && asyncLoading)) && (
        <Flex
          left={0}
          right={0}
          position="absolute"
          justifyContent="center"
          alignItems="center"          
          height="15px"
          width="15px"
          mx="auto"
        >
          <Flex
            left={0}
            right={0}
            position="absolute"
            justifyContent="center"
            alignItems="center"
            fontSize={"2rem"}
            height="15px"
            width="15px"
            mx="auto"
          >
            <ClipLoader color="#FFF" size="1.4rem" />
          </Flex>
        </Flex>
      )}
      {label ? (
        <Text
          color="inherit"
          opacity={loading || (showAsyncLoad && asyncLoading) ? 0 : 1}
          id={label}
          fontSize={fontSize}
          fontWeight={fontWeight}
        />
      ) : (
        <Text
          color="inherit"
          opacity={loading || (showAsyncLoad && asyncLoading) ? 0 : 1}
          fontSize={fontSize}
          fontWeight={fontWeight}          
        >
          {children}
        </Text>
      )}
    </ButtonBase>
  );
};

Button.defaultProps = {
  as: "button",
  width: "100%",
  fontSize: "1.2rem",
  fontWeight: "500",  
};

Button.propTypes = {  
  fontSize: PropTypes.string,
  as: PropTypes.string,
  width: PropTypes.string,
};