import { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineCheck } from "react-icons/ai";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { MdOutlineDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Flex, Image, InputDebounce, Loader, Text } from "atoms";
import { addProviderMachines, deleteProviderMachine } from "redux/actions";
import { config } from "config";

export const ProviderMachines = ({
  id,
  machines,
}) => {

  const [ accordion, setAccordion ] = useState(false);
  const [ query, setQuery ] = useState("");
  const [ localLoading, setLocalLoading ] = useState(false);
  const [ results, setResults ] = useState([]);
  const [ selected, setSelected ] = useState([]);

  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.provider)

  useEffect(() => {
    async function fetchMachines(){
      setLocalLoading(true);
      const { data: machines } = await axios.get(config.STRAPI_URL+"/brand-products?name_contains=" + query)      
      setResults(machines);
      setLocalLoading(false);
    }
    if(query){
      fetchMachines();
    }
  }, [dispatch, query]);

  const handleSelection = (machine) => {
    setSelected([
      ...selected,
      machine
    ]);
  }

  const removeSelection = (machine) => {
    setSelected(selected.filter(item => item._id !== machine._id));
  }

  const handleSave = async () => {
    if(selected.length === 0) return;
    let _machines = []
    selected.forEach((machine) => {
      _machines.push(machine._id);
    });
    dispatch(addProviderMachines(id, {machines: _machines}))    
  }

  return (
    <Box
      borderRadius="0.4rem"
      boxShadow="0 0 0.5rem 0.1rem rgba(0,0,0,0.1)"
      p="1.5rem"
      bg="white"
    >
      {(loading || localLoading) && <Loader/>}
      <Box
        borderBottom="0.1rem solid"
        borderColor="grey.50"
        pb="1rem"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          cursor="pointer"
          onClick={() => setAccordion(!accordion)}
        >
          <Text
            fontSize="1.4rem"
            fontWeight="600"
            color="accent.500"
            textTransform="uppercase"
            mb="1rem"
          >
            Add Machines
          </Text>
          <Box
            fontSize="1.4rem"            
          >
            {accordion ? <BsChevronUp/> : <BsChevronDown/>}
          </Box>
        </Flex>
        {accordion && <Box
          bg="grey.50"
          borderRadius="0.4rem"
          p="2rem"
        >
          <InputDebounce
            name="search"
            type="text"
            id="search"                    
            label="Search Machines"
            placeholder="Enter Machine Name"
            value={query}          
            setValue={setQuery}                        
            mb="2rem"            
          />
          <Flex
            overflowX="auto"
            style={{ gap: "2rem"}}
            p="1.5rem 0"
          >
            {selected?.map((machine) => (
              <Flex
                key={machine?._id} 
                flexDirection="column"              
                alignItems="center"   
                width="24rem"
                bg="white"
                borderRadius="0.4rem"
                boxShadow="0 0 0.5rem 0.1rem rgba(0,0,0,0.1)"
                cursor="pointer"
                onClick={() => removeSelection(machine)}
                zIndex="1"
              >
                <Flex
                  position="absolute"
                  top="-1rem"
                  right="-1rem"
                  bg="success.100"
                  width="3rem"
                  height="3rem"
                  borderRadius="50%"
                  zIndex="1"
                  fontSize="2rem"
                  color="white"
                  alignItems="center"
                  justifyContent="center"
                >
                  <AiOutlineCheck/>
                </Flex>
                <Box
                  width="24rem"
                  height="16rem"
                  borderRadius="0.4rem"
                  bg="white"  
                  borderBottom="0.1rem solid"
                  borderColor="grey.50"                
                >              
                  <Image
                    src={machine?.images[0]?.url || "/assets/machine-placeholder.png"}
                    alt={machine?.name}
                    width="100%"
                    height="100%"
                    borderRadius="0.4rem"
                    objectFit="contain"                
                  />
                </Box>
                <Box p="2rem">
                  <Text
                    fontSize="1.4rem"
                    fontWeight="500"
                    color="grey.300"  
                    textAlign="center"                
                  >
                    {machine?.name}
                  </Text>
                  <Box
                    width="4rem"
                    height="4rem"
                    mx="auto"
                    mt="1rem"
                  >
                    <Image
                      src={machine?.brandId?.logo?.url || "/assets/brand-placeholder.png"}
                      alt={machine?.brandId?.name}
                      width="4rem"
                      height="4rem"                      
                      objectFit="contain"
                    />     
                  </Box>  
                </Box>                  
              </Flex>
            ))}
            {results?.filter((machine) => !machines?.find((m) => m?._id === machine?._id)).filter((machine) => !selected?.find((m) => m?._id === machine?._id)).map((machine) => (
              <Flex
                key={machine?._id} 
                flexDirection="column"              
                alignItems="center"   
                width="24rem"
                bg="white"
                borderRadius="0.4rem"
                boxShadow="0 0 0.5rem 0.1rem rgba(0,0,0,0.1)"
                cursor="pointer"
                onClick={() => handleSelection(machine)}
              >
                <Box
                  width="24rem"
                  height="16rem"
                  borderRadius="0.4rem"
                  bg="white"  
                  borderBottom="0.1rem solid"
                  borderColor="grey.50"                
                >              
                  <Image
                    src={machine?.images[0]?.url || "/assets/machine-placeholder.png"}
                    alt={machine?.name}
                    width="100%"
                    height="100%"
                    borderRadius="0.4rem"
                    objectFit="contain"                
                  />
                </Box>                
                <Box p="2rem">
                  <Text
                    fontSize="1.4rem"
                    fontWeight="500"
                    color="grey.300"  
                    textAlign="center"                
                  >
                    {machine?.name}
                  </Text>
                  <Box
                    width="4rem"
                    height="4rem"
                    mx="auto"
                    mt="1rem"
                  >
                    <Image
                      src={machine?.brandId?.logo?.url || "/assets/brand-placeholder.png"}
                      alt={machine?.brandId?.name}
                      width="4rem"
                      height="4rem"                      
                      objectFit="contain"
                    />     
                  </Box>  
                </Box>                                  
              </Flex>
            ))}
          </Flex>    
          <Button
            variant="success"
            p="0.8rem 2rem"
            borderRadius="0.4rem"
            onClick={handleSave}
          >
            Save Selection
          </Button>      
        </Box>}
      </Box>

      {machines?.length > 0 && <>
        <Text
          fontSize="1.2rem"
          fontWeight="600"
          color="accent.500"
          textTransform="uppercase"
          mt="2rem"
        >
          ADDED MACHINES
        </Text>
        <Flex        
          borderRadius="0.4rem"
          p="1rem"
          bg="#f5f5f5"
          boxShadow="0 0 0.5rem 0.1rem rgba(0,0,0,0.1)"          
          style={{ gap: "2rem" }}
          mt="1rem"
          mb="2rem"
          overflowX="auto"          
        >        
          {machines?.map((machine) => (
            <Flex
              key={machine?._id} 
              flexDirection="column"              
              alignItems="center"   
              width="28rem"
              bg="white"
              boxShadow="0 0 0.5rem 0.1rem rgba(0,0,0,0.1)"
            >
              <Box
                width="28rem"
                height="16rem"
                borderRadius="0.4rem" 
                borderBottom="0.1rem solid"
                borderColor="grey.50"               
              >              
                <Image
                  src={machine?.images[0]?.url || "/assets/machine-placeholder.png"}
                  alt={machine?.name}
                  width="100%"
                  height="100%"
                  borderRadius="0.4rem"
                  objectFit="contain"                
                />
              </Box>
              <Box>
                <Box p="2rem">
                  <Text
                    fontSize="1.4rem"
                    fontWeight="500"
                    color="grey.300"    
                    textAlign="center"              
                  >
                    {machine?.name}
                  </Text>           
                  <Box
                    width="4rem"
                    height="4rem"
                    mx="auto"
                    mt="1rem"
                  >
                    <Image
                      src={machine?.brandId?.logo?.url || "/assets/brand-placeholder.png"}
                      alt={machine?.brandId?.name}
                      width="4rem"
                      height="4rem"                      
                      objectFit="contain"
                    />     
                  </Box>                    
                </Box>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  fontSize="1.6rem"
                  bg="danger.100"
                  width="28rem"
                  color="white"
                  borderRadius="0 0 0.4rem 0.4rem"
                  p="0.5rem"
                  cursor="pointer"                     
                  onClick={() => dispatch(deleteProviderMachine(id, machine?._id))}           
                >
                  <MdOutlineDelete/>
                </Flex>
              </Box>
            </Flex>
          ))}       
        </Flex>
      </>}
    </Box>
  )
}
