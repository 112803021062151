import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi";
import { Avatar } from "@mui/material";

import { Box, Flex, Image, Text } from "atoms";
import { logout } from "redux/actions";
import { getAssetURL } from "utils/utilities";

export const Header = () => {

  const dispatch = useDispatch();
  const [ avatarHover, setAvatarHover ] = useState(false)  
  const { user } = useSelector(state => state.user);

  const avatarDropdown = [    
    {
      "name": user?.name || user?.email,
      "Icon": <HiOutlineUserCircle />,
      "onClick": () => console.log("Profile")
    },
    {
      "name": "Logout",
      "Icon": <AiOutlineLogout />,
      "onClick": () => dispatch(logout())
    },
  ]

  return (
    <Box
      width="100%"
      height="5rem"      
      position="sticky"
      top="0"
      zIndex="99"
      bg="white"    
      boxShadow="0px -2px 4px -1px rgba(0,0,0,0.2),0px -4px 5px 0px rgba(0,0,0,0.14),0px -1px 10px 0px rgba(0,0,0,0.12)"
    >      
      <Flex
        alignItems="center"
        justifyContent="space-between"
        height="100%"
        pr="2rem"
      >
        {/* APP LOGO */}
        <Link to="/">        
          <Image
            src={getAssetURL("favicon.png")}
            alt="logo"
            width="4rem"            
            left="1.5rem"
          />
        </Link>
        <Flex
          alignItems="center"          
        >                                      
          {/* AVATAR */}   
          <Flex alignItems="center">          
            <Flex
              pt="0.1rem"
              ml="1rem"
              cursor="pointer"
              onMouseEnter={() => setAvatarHover(true)}
              onMouseLeave={() => setAvatarHover(false)}
              style={{ gap: "0.8rem" }}
            >
              <Box width="3rem" height="3rem">
                <Avatar
                  style={{
                    width: "3.2rem",
                    height: "3.2rem",
                  }}
                  src={user?.image || ""}
                />
              </Box>
              <Box
                borderLeft="2px solid"
                borderColor="grey.50"
                height="3rem"
                my="auto"
              />
              <Box>
                <Text fontSize="1.2rem" fontWeight="500" color="grey.300">
                  {user?.name}
                </Text>
                <Text
                  fontSize="1rem"
                  fontWeight="600"
                  color="grey.100"
                  textTransform="uppercase"
                >
                  {user?.role === 1 ? "SuperAdmin" : user?.role === 2 ? "Admin" : "User"}
                </Text>
              </Box>
              {/* AVATAR DROPDOWN */}
              {avatarHover && (
                <Box
                  position="absolute"
                  top="105%"
                  right="-1rem"
                  bg="white"
                  color="grey.300"
                  borderRadius="0.2rem"
                  p="0.5rem 2rem"
                  boxShadow="0 0 0.5rem rgba(0, 0, 0, 0.2)"
                  transition="all 0.4s ease-in-out"
                  zIndex="199"
                  minWidth="22rem"
                  maxWidth="25rem"
                >
                  <Box
                    position="absolute"
                    top="-0.7rem"
                    left="82%"
                    width="0"
                    height="0"
                    borderLeft="0.7rem solid transparent"
                    borderRight="0.7rem solid transparent"
                    borderBottom="0.7rem solid white"
                  />
                  {avatarDropdown.map((item, index) => (
                    <Flex
                      key={index}
                      fontSize="2rem"
                      alignItems="center"
                      py="1rem"
                      borderBottom={
                        index + 1 === avatarDropdown.length
                          ? "unset"
                          : "1px solid"
                      }
                      borderColor="whitesmoke"
                      onClick={item.onClick}
                      _hover={{
                        color: "primary.500",
                        transition: "all 0.3s ease-in-out",
                      }}
                    >
                      {item.Icon}
                      <Text ml="0.5rem" mt="-0.15rem">
                        {item.name}
                      </Text>
                    </Flex>
                  ))}
                </Box>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}