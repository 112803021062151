import { useEffect, useState } from "react";
import { AiFillEye, AiOutlineEdit } from "react-icons/ai";
import { FaLocationArrow } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DataGrid } from "@material-ui/data-grid";

import { Box, Button, Flex, Image, Loader, Text } from "atoms";
import { CreateProviderModal } from "molecules";
import { getProviders, toggleProviderStatus } from "redux/actions";
import { providerConstants } from "redux/constants";
import { useNavigate } from "react-router-dom";

export const ProviderPage = () => {

  const [ modal, setModal ] = useState(false);
  const [ page, setPage ] = useState(1);

  const navigator = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, message, totalCount, resultPerPage, providers } = useSelector(state => state.provider);

  useEffect(() => {
    dispatch(getProviders({page}));
  }, [dispatch, page]);

  useEffect(() => {
    if(message){
      toast.success(message);
      dispatch({ type: providerConstants.RESET_PROVIDER });
    }
    if(error){
      toast.error(error);
      dispatch({ type: providerConstants.RESET_PROVIDER });
    }
  }, [error, message, dispatch]);

  const columns = [     
    {field: "index", headerName: "#", align: "center",  headerAlign: "center", renderCell: (params) => (
      <Flex
        alignItems="center"
        justifyContent="center"
        fontSize="1.6rem" 
        style={{gap: "1rem"}}       
      >
        <Box
          color={params.row.status ? "success.500" : "danger.500"}
          cursor="pointer"
        >
          <AiFillEye
            onClick={() => dispatch(toggleProviderStatus(params.row.id, {status: !params.row.status}))}
          />
        </Box>
        <Box
          color="warning.500"
          cursor="pointer"
        >
          <AiOutlineEdit
            onClick={() => navigator(`/manage-provider/${params.row.id}`)}
          />
        </Box>
      </Flex>
    )},
    {field: "icon", headerName: "Logo", minWidth: "50", align: "center", headerAlign: "center",   
      renderCell: (params) => (      
        <Box
          width="6rem"
          height="6rem"
          p="1rem" 
          borderRadius="50%"
          bg={params.row.image ? "white" : "grey.100"}         
        >
          {params.row.image && <Image
            src={params.row.image?.url}
            alt={params.row.name}
            width="100%"
            height="100%"
            objectFit="contain"
            borderRadius="50%"
          />}
        </Box>
      )     
    },
    {field: "name", headerName: "Name", flex: 1, minWidth: "220"},  
    {field: "location", headerName: "Location", headerAlign: "center", minWidth: "50", renderCell: (params) => (
      <Flex
        alignItems="center"
        justifyContent="center"
        fontSize="2rem"
        color="accent.500"
        cursor="pointer"
        as="a"
        href={params.row.mapUrl || `https://www.google.com/maps/search/?api=1&query=${params.row.coordinates[1]},${params.row.coordinates[0]}`}
        target="_blank"
        rel="noreferrer"
      >
        <FaLocationArrow/>
      </Flex>
    ),    
    align: "center",    
    },
    {field: "phone", headerName: "Phone", flex: 1, minWidth: "100"},    
  ];

  const rows = [];
  providers?.forEach((provider, index) => {    
    rows.push({
      index: index + 1,
      id: provider._id,
      name: provider.name,      
      image: provider.image,
      coordinates: provider.coordinates,
      phone: provider.phone,
      mapUrl: provider.mapUrl,
      status: provider.status,
    });
  });

  return (
    <Box>
      {loading && <Loader />}
      <CreateProviderModal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        ariaHideApp={() => setModal(false)}        
      />  
      <Box        
        p="1rem 2rem" 
        maxHeight="calc(100vh - 8rem)"  
        overflowY="auto"         
      >
        <Flex
          alignItems={{xs: "start", md: "center"}}
          justifyContent="space-between"
          flexDirection={{xs: "column", md: "row"}}
          style={{gap: "0.5rem"}}
          my="1rem"
        >
          <Text            
            fontSize="2rem"
            fontWeight="600"
            color="primary.500"
            textTransform="uppercase"
          >
            Providers
          </Text>
          <Box>                                                               
            <Button         
              variant="primary"                       
              width="fit-content"
              height="fit-content"
              p="0.6rem 1.5rem"
              fontSize="1.4rem"  
              borderRadius="0.4rem"  
              onClick={() => {                
                setModal(true);                  
              }}                       
            >
              + Create
            </Button>                                          
          </Box>     
        </Flex> 

        <Box 
          my="2rem"          
        >         
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={rows.length}
            disableSelectionOnClick                
            autoHeight
            style={{ backgroundColor: "white" }}                    
          />        
        </Box>  
        {resultPerPage < totalCount &&  <Flex
          justifyContent="center"
          my="4rem"
        >
          <Pagination
            activePage={page}
            itemsCountPerPage={resultPerPage}
            totalItemsCount={totalCount}
            onChange={(e) => setPage(e)}
            nextPageText="Next"
            prevPageText="Prev"
            firstPageText="1st"
            lastPageText="Last"
            itemClass="pagination-item"
            linkClass="page-link"
            activeClass="pagination-item-active"
            activeLinkClass="pagination-link-active"
          />
        </Flex>}       
        {totalCount > 0 &&  <Text
          textAlign="center"
          fontSize="1.4rem"
          fontWeight="600"
          color="secondary.200"
          my="2rem"
        >
          Total Entries Found : {totalCount}
        </Text>}
      </Box>      
    </Box>
  )
}