import { Flex, Text } from "atoms";

export const Footer = () => {  
  return (
    <Flex
      as="footer"      
      px={{ xs: "3rem", md: "6rem", xm: "9rem", lg: "17rem" }}      
      py="1rem"
      borderTop="1px solid #eaeaea"
      justifyContent="center"
      alignItems="center"
    >
      <Text
        fontSize="1.2rem"
        textAlign="center"
      >
        Powered by <Text 
          as="span"
          fontSize="1.2rem"
          color="primary.100"
          fontWeight="500"
        >
          <a
            href="https://pgkonnect.com/"
            target="_blank"
            rel="noreferrer"
          >
            PGKPL
          </a>
        </Text>
      </Text>   
    </Flex>
  )
}