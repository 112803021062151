import { useEffect } from "react";

import SEO from "seo/SEO";
import { HomePage } from "templates";
import { scrollToTop } from "utils/utilities";

export const Home = () => {
  
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>      
      <SEO
        title="Dashboard | Photonence Admin"
        description="Dashboard | Photonence Admin"        
      /> 
      <HomePage/>
    </>
  );
};

export default Home;
