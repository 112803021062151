const host = window.location.hostname;
let apiURL = host === "localhost" ? "http://localhost:2000" : process.env.REACT_APP_API_URL;
let strapiUrl = host === "localhost" ? "http://localhost:1337" : process.env.REACT_APP_STRAPI_URL;

export const config = {  
  "STRAPI_URL": strapiUrl,
  "BASE_URL": apiURL,  
  "API_URL": apiURL + "/api/v1", 
  "MEDIA_URL": apiURL + "/media",
  "APP_NAME": "Photonence Admin",
  "APP_VERSION": "1.0.0"
}