import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

import { loadUser } from "redux/actions";
import { appConstants } from "redux/constants";
import { AppRoutes } from "routes";
import { theme } from "styles";

import "styles/globals.css";
import "styles/style.css";

function App() {    

  const dispatch = useDispatch();
  const { message, error  } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    if(message){
      toast.success(message);
      dispatch({ type: appConstants.RESET_APP });
    }
    if(error){
      toast.error(error);
      dispatch({ type: appConstants.RESET_APP });
    }
  }, [message, error, dispatch]);

  return (    
    <ThemeProvider theme={theme}>              
      <ToastContainer         
        style={{ fontSize: "1.4rem" }}  
        position="top-right"      
      />
      <Router>
        <AppRoutes />
      </Router>           
    </ThemeProvider>    
  );
}

export default App;
