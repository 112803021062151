import { providerConstants } from "redux/constants";
import Axios from "utils/axios";

export const createProvider = (form) => {
  return async dispatch => {
    try{
      dispatch({
        type: providerConstants.CREATE_PROVIDER_REQUEST
      })
      const { data } = await Axios.post("/providers", form)
      dispatch({
        type: providerConstants.CREATE_PROVIDER_SUCCESS,
        payload: {
          data: data?.data,
          message: data?.message
        }        
      })
    }catch(error){
      dispatch({
        type: providerConstants.CREATE_PROVIDER_FAILURE,
        payload: error.response.data.error
      })
    }
  }
}

export const getProviders = (filter) => {
  return async dispatch => {
    try{
      dispatch({
        type: providerConstants.GET_PROVIDERS_REQUEST
      })
      let URL = "/providers?page=" + filter.page || 1      
      const { data } = await Axios.get(URL)
      dispatch({
        type: providerConstants.GET_PROVIDERS_SUCCESS,
        payload: data?.data        
      })
    }catch(error){
      dispatch({
        type: providerConstants.GET_PROVIDERS_FAILURE,
        payload: error.response.data.error
      })
    }
  }
}

export const getProviderDetail = (id) => {
  return async dispatch => {
    try{
      dispatch({
        type: providerConstants.GET_PROVIDER_DETAILS_REQUEST
      })
      const { data } = await Axios.get("/providers/" + id)
      dispatch({
        type: providerConstants.GET_PROVIDER_DETAILS_SUCCESS,
        payload: data?.data        
      })
    }catch(error){
      dispatch({
        type: providerConstants.GET_PROVIDER_DETAILS_FAILURE,
        payload: error.response.data.error
      })
    }
  }
}

export const toggleProviderStatus = (id, form) => {
  return async dispatch => {
    try{
      dispatch({
        type: providerConstants.UPDATE_PROVIDER_STATUS_REQUEST
      })
      const { data } = await Axios.put("/providers/" + id + "/status", form)
      dispatch({
        type: providerConstants.UPDATE_PROVIDER_STATUS_SUCCESS,
        payload: {
          data: data?.data,
          message: data?.message
        }
      })
    }catch(error){
      dispatch({
        type: providerConstants.UPDATE_PROVIDER_STATUS_FAILURE,
        payload: error.response.data.error
      })
    }
  }
}

export const addProviderMachines = (id, form) => {
  return async dispatch => {
    try{
      dispatch({
        type: providerConstants.ADD_MACHINES_TO_PROVIDER_REQUEST
      })
      const { data } = await Axios.post("/providers/" + id + "/machines", form)
      dispatch({
        type: providerConstants.ADD_MACHINES_TO_PROVIDER_SUCCESS,
        payload: {          
          message: data?.message
        }
      })
      dispatch(getProviderDetail(id))
    }catch(error){
      dispatch({
        type: providerConstants.ADD_MACHINES_TO_PROVIDER_FAILURE,
        payload: error.response.data.error
      })
    }
  }
}

export const deleteProviderMachine = (id, machineId) => {
  return async dispatch => {
    try{
      dispatch({
        type: providerConstants.DELETE_PROVIDER_MACHINE_REQUEST
      })
      const { data } = await Axios.put("/providers/" + id + "/machines" , { machineId })
      dispatch({
        type: providerConstants.DELETE_PROVIDER_MACHINE_SUCCESS,
        payload: {          
          message: data?.message
        }
      })
      dispatch(getProviderDetail(id))
    }catch(error){
      dispatch({
        type: providerConstants.DELETE_PROVIDER_MACHINE_FAILURE,
        payload: error.response.data.error
      })
    }
  }
}

export const updateProvider = (id, form) => {
  return async dispatch => {
    try{
      dispatch({
        type: providerConstants.UPDATE_PROVIDER_REQUEST
      })
      const { data } = await Axios.put("/providers/" + id, form)
      dispatch({
        type: providerConstants.UPDATE_PROVIDER_SUCCESS,
        payload: {
          data: data?.data,
          message: data?.message
        }        
      })
      dispatch(getProviderDetail(id))
    }catch(error){
      dispatch({
        type: providerConstants.UPDATE_PROVIDER_FAILURE,
        payload: error.response.data.error
      })
    }
  }
}