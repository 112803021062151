import { Formik, Form } from "formik";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Box, Button, Flex, Image, Input, InputSelect, Text } from "atoms";
import { createProvider } from "redux/actions";
import { states } from "utils/constant-data";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "4px",
    padding: "0px",
    border: "none",    
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "299"
  },
};

const validationSchema = Yup.object({  
  name: Yup.string().required("Name is required"),
  phone: Yup.string().required("Phone is required"),
  latitude: Yup.string().required("Latitude is required"),
  longitude: Yup.string().required("Longitude is required"),
});

export const CreateProviderModal = ({
  isOpen, 
  onRequestClose, 
  ariaHideApp,    
}) => {  
  
  const dispatch = useDispatch();      
  const { loading } = useSelector(state => state.provider);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {                         
    const formData = new FormData();          
    formData.set("name", values.name);
    formData.set("phone", values.phone);    
    if(values.email) formData.set("email", values.email);
    formData.set("latitude", values.latitude);
    formData.set("longitude", values.longitude);
    if(values.address) formData.set("address", values.address);
    if(values.city) formData.set("city", values.city);
    if(values.state) formData.set("state", values.state);
    if(values.pincode) formData.set("pincode", values.pincode);
    if(values.country) formData.set("country", values.country);   
    if(values.image) formData.append("image", values.image);
    if(values.mapUrl) formData.append("mapUrl", values.mapUrl);
    dispatch(createProvider(formData));     
    resetForm();
    onRequestClose();     
    setSubmitting(false);          
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={ariaHideApp}      
    >
      <Box          
        width="100%" 
        minWidth={{xs: "28rem", md: "60rem"}}       
        bg="white"
        px={{xs: "1rem", md: "3rem"}} 
        py="1rem"             
        maxWidth={{xs: "92vw", md: "70rem"}}           
        maxHeight={{xs: "90vh", md: "80vh"}}
        overflowY="auto"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"          
        >
          <Text as="h2" textTransform="capitalize">
            Create Provider
          </Text>        
          <Box
            onClick={onRequestClose}            
            cursor="pointer"
            zIndex={2}
            color="danger.100"
            fontSize="2.5rem"
            fontWeight="600"
            mt="0.2rem"
          >
            &times;          
          </Box>         
        </Flex>
        <Flex            
          height="100%"          
          justifyContent="center"
          alignItems="center" 
          mx={{xs: "1rem", md: "unset"}}                                                   
        >
          <Formik
            initialValues={{
              name: "",
              email: "",
              phone: "",              
              address: "",                
              city: "",
              state: "",
              country: "India",
              pincode: "",            
              rating: "",
              latitude: "",
              longitude: "",
              image: null,
              mapUrl: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}          
            enableReinitialize
          >
            {({ touched, errors, values, setFieldValue }) => {
              return (
                <Form style={{ width: "100%" }}>                
                  <Box>
                    <Text                        
                      fontSize="1.4rem"
                      fontWeight="600"
                      color="accent.500"
                      mb="0.5rem"
                    >
                      Basic Details
                    </Text>
                    <Flex
                      flexDirection={{xs: "column-reverse", md: "row"}}
                      style={{gap: "2rem"}}
                    >
                      <Box width="100%">
                        <Box width="100%">
                          <Input
                            name="name"
                            type="text"
                            id="name"
                            touched={touched.name}
                            formik={true}
                            errors={errors.name}      
                            label="Name*"                      
                            value={values.name}                                                                              
                            mb="0.8rem"                                                             
                          />     
                        </Box> 
                        <Flex
                          flexDirection={{xs: "column", md: "row"}}                     
                          style={{gap: "2rem"}}
                          width="100%"
                          mb="0.8rem"
                        >
                          <Box width="100%">
                            <Input
                              name="email"
                              type="email"
                              id="email"
                              touched={touched.email}
                              formik={true}
                              errors={errors.email}      
                              label="Email Address"                      
                              value={values.email}                                  
                            />     
                          </Box> 
                          <Box width="100%">
                            <Input
                              name="phone"
                              type="text"
                              id="phone"
                              touched={touched.phone}
                              formik={true}
                              errors={errors.phone}      
                              label="Mobile Number"
                              value={values.phone}                                    
                            />     
                          </Box>                                       
                        </Flex>
                      </Box>
                      <Box>
                        <Box
                          width="10rem"
                          height="10rem"                          
                          borderRadius="50%"
                          boxShadow="0 0 0.5rem rgba(0, 0, 0, 0.2)"
                        >
                          <Image
                            src={values.image ? values.image : "/assets/machine-placeholder.png"}
                            alt="image"
                            width="100%"
                            height="100%"
                            objectFit="contain"
                            borderRadius="50%"
                          />
                        </Box>
                        <Button
                          width="10rem"
                          type="button"
                          variant="accent"
                          p="0.5rem"
                          fontSize="1rem"
                          mt="0.8rem"
                          borderRadius="0.5rem"
                          as="label"
                          htmlFor="image"
                          textAlign="center"
                        >
                          Upload Image
                        </Button>
                        <input
                          type="file"
                          id="image"
                          name="image"
                          accept="image/*"
                          style={{display: "none"}}
                          onChange={(e) => {
                            const reader = new FileReader();
                            reader.onload = () => {
                              if (reader.readyState === 2) {
                                setFieldValue("image", reader.result);
                              }
                            };
                            reader.readAsDataURL(e.target.files[0]);                            
                          }}                          
                        />
                      </Box>
                    </Flex>                    
                    <Flex
                      flexDirection={{xs: "column", md: "row"}}
                      style={{gap: "2rem"}}
                      mb="0.8rem"
                    >
                      <Box width="100%">
                        <Input
                          name="address"
                          type="text"
                          id="address"
                          touched={touched.address}
                          formik={true}
                          errors={errors.address}      
                          label="Address"                      
                          value={values.address}
                          as="textarea"                      
                          rows={5}                          
                        />  
                      </Box>
                      <Box width="100%">
                        <Box width="100%">
                          <Input
                            name="city"
                            type="text"
                            id="city"
                            touched={touched.city}
                            formik={true}
                            errors={errors.city}      
                            label="City"
                            value={values.city}                                                             
                          />     
                        </Box> 
                        <Box width="100%">
                          <Input
                            name="pincode"
                            type="text"
                            id="pincode"
                            touched={touched.pincode}
                            formik={true}
                            errors={errors.pincode}      
                            label="Pincode"
                            value={values.pincode}                                                              
                          />     
                        </Box> 
                      </Box>
                    </Flex>
                    <Flex
                      flexDirection={{xs: "column", md: "row"}}                     
                      style={{gap: "2rem"}}
                      width="100%"
                    >
                      <Box width="100%" mt="1rem">
                        <InputSelect
                          value={values.state}
                          setValue={setFieldValue}
                          options={states}
                          label="State"                                             
                          formik={true}
                          fieldName="state"
                          touched={touched.state}
                          errors={errors.state}  
                          name="state" 
                          id="state"
                          placeholder="Select State"                                                                                   
                        />     
                      </Box> 
                      <Box width="100%">
                        <Input
                          name="country"
                          type="text"
                          id="country"
                          touched={touched.country}
                          formik={true}
                          errors={errors.country}      
                          label="Country"
                          value={values.country}
                          disabled={true}                          
                        />     
                      </Box>                                       
                    </Flex>
                    <Box my="1rem">
                      <Text                        
                        fontSize="1.4rem"
                        fontWeight="600"
                        color="accent.500"
                        mb="0.5"
                      >
                        Location Details
                      </Text>
                      <Flex
                        style={{gap: "2rem"}}
                      >
                        <Box width="100%">
                          <Input
                            name="latitude"
                            type="text"
                            id="latitude"
                            touched={touched.latitude}
                            formik={true}
                            errors={errors.latitude}      
                            label="Latitude*"
                            value={values.latitude}                            
                          />     
                        </Box> 
                        <Box width="100%">
                          <Input
                            name="longitude"
                            type="text"
                            id="longitude"
                            touched={touched.longitude}
                            formik={true}
                            errors={errors.longitude}      
                            label="Longitude*"
                            value={values.longitude}                                                                                            
                          />     
                        </Box> 
                      </Flex>  
                      <Box width="100%">
                        <Input
                          name="mapUrl"
                          type="text"
                          id="mapUrl"
                          touched={touched.mapUrl}
                          formik={true}
                          errors={errors.mapUrl}      
                          label="Map URL"
                          value={values.mapUrl}                                                                                                                      
                        />     
                      </Box>                    
                    </Box>                                                                                                                                                                                                      
                  </Box>

                  <Flex 
                    my="1rem"                    
                    flexDirection={{ xs: "column-reverse", md: "row"}}
                    justifyContent={{xs: "center", md: "flex-end"}}
                  >                    
                    <Button
                      variant="primary"
                      width={{xs: "100%", md: "fit-content"}}                  
                      borderRadius="0.4rem"
                      px="3rem"
                      py="1rem"
                      type="submit"                                        
                      fontSize="1.3rem"
                      borderBottomRightRadius={{xm: "0.4rem"}}
                      loading={loading}                      
                    >
                      Create Provider
                    </Button>
                  </Flex>                  
                </Form>
              )
            }}
          </Formik>
        </Flex>           
      </Box>      
    </Modal>
  )
}