import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

import { Loader } from "atoms";
import NotFound from "pages/not-found";
import { getInitialData } from "redux/actions";
import ProtectedRoutes from "./ProtectedRoutes";
import Home from "pages/home";
import Login from "pages/login";
import Provider from "pages/provider";
import ManageProvider from "pages/manage-provider";

export const AppRoutes = () => {  
  
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.app);      
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {    
    if(isAuthenticated) dispatch(getInitialData())    
  }, [ dispatch, isAuthenticated ]) 

  return (
    <Suspense fallback={<Loader />}>
      {loading ? 
        <Loader /> 
      : <Routes>        
        <Route path="/login" exact element={<Login/>} />
        <Route path="/" exact element={<ProtectedRoutes>
          <Home />
        </ProtectedRoutes>} />                         
        <Route path="/providers" exact element={<ProtectedRoutes>
          <Provider />
        </ProtectedRoutes>} />                         
        <Route path="/manage-provider/:id" exact element={<ProtectedRoutes>
          <ManageProvider />
        </ProtectedRoutes>} />                 
        <Route path="*" element={<NotFound />} />
      </Routes>}
    </Suspense>
  );
};