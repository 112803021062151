import React, { useState } from "react";

import { Box, Flex } from "atoms";
import { SidebarOption } from "molecules";
import { navData } from "utils/constant-data";
import { isMobile } from "utils/utilities";

export const Sidebar = () => {

  const [ expanded, setExpanded ] = useState(isMobile() === true ? false : true);  

  return (
    <Box
      flex={expanded ? "0.7" : "unset"}
      position="sticky"
      top="5rem"
      overflowY="auto"
      height="calc(100vh - 9rem)"
      bg="white"      
      minWidth={{ xs: "fit-content" }}
      borderRight="2px solid"
      borderColor="whitesmoke"
    >
      <Box p="1.5rem 1rem">
        {navData.map((item, index) => (          
          <SidebarOption
            key={index}
            Icon={item.Icon}
            title={item.title}
            to={item.to}
            description={item.description}
            expanded={expanded}            
          />            
        ))}
      </Box>
      <Flex
        position="absolute"
        bottom="0"
        p="1.5rem 1rem"
        fontSize="3rem"
        fontWeight="600"
        color="primary.500"
        minWidth="100%"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? "<" : ">"}
      </Flex>
    </Box>
  );
};