import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Box, Loader, Text } from "atoms";
import { ProviderInfo, ProviderMachines } from "molecules";
import { providerConstants } from "redux/constants";

export const ManageProviderPage = () => {

  const dispatch = useDispatch();
  const { provider, loading, error, message } = useSelector(state => state.provider);

  useEffect(() => {
    if(message){
      toast.success(message);
      dispatch({ type: providerConstants.RESET_PROVIDER })
    }
    if(error){
      toast.error(error);
      dispatch({ type: providerConstants.RESET_PROVIDER })
    }
  }, [dispatch, error, message]);

  return (
    <Box
      p="2rem"
    >
      {loading && <Loader/>}
      <Text
        fontSize="2rem"
        fontWeight="500"
        mb="2rem"
        color="accent.100"
      >
        {provider?.name}
      </Text>
      <ProviderInfo
        id={provider?._id}
      />
      <Box my="2rem" />
      <ProviderMachines
        id={provider?._id}        
        machines={provider?.machines}
      />      
    </Box>
  )
}