export const appConstants = {
  GET_INITIAL_DATA_REQUEST: "GET_INITIAL_DATA_REQUEST",
  GET_INITIAL_DATA_SUCCESS: "GET_INITIAL_DATA_SUCCESS",
  GET_INITIAL_DATA_FAILURE: "GET_INITIAL_DATA_FAILURE",  
  ADD_BRAND: "ADD_BRAND",
  RESET_APP: "RESET_APP",
  RESET: "RESET"
}

export const userConstants = {
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",  
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",    
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  RESET_USER: "RESET_USER",
  RESET: "RESET"
}

export const providerConstants = {
  GET_PROVIDERS_REQUEST: "GET_PROVIDERS_REQUEST",
  GET_PROVIDERS_SUCCESS: "GET_PROVIDERS_SUCCESS",
  GET_PROVIDERS_FAILURE: "GET_PROVIDERS_FAILURE",
  CREATE_PROVIDER_REQUEST: "CREATE_PROVIDER_REQUEST",
  CREATE_PROVIDER_SUCCESS: "CREATE_PROVIDER_SUCCESS",
  CREATE_PROVIDER_FAILURE: "CREATE_PROVIDER_FAILURE",
  GET_PROVIDER_DETAILS_REQUEST: "GET_PROVIDER_DETAILS_REQUEST",
  GET_PROVIDER_DETAILS_SUCCESS: "GET_PROVIDER_DETAILS_SUCCESS",
  GET_PROVIDER_DETAILS_FAILURE: "GET_PROVIDER_DETAILS_FAILURE",
  UPDATE_PROVIDER_STATUS_REQUEST: "UPDATE_PROVIDER_STATUS_REQUEST",
  UPDATE_PROVIDER_STATUS_SUCCESS: "UPDATE_PROVIDER_STATUS_SUCCESS",
  UPDATE_PROVIDER_STATUS_FAILURE: "UPDATE_PROVIDER_STATUS_FAILURE",
  ADD_MACHINES_TO_PROVIDER_REQUEST: "ADD_MACHINES_TO_PROVIDER_REQUEST",
  ADD_MACHINES_TO_PROVIDER_SUCCESS: "ADD_MACHINES_TO_PROVIDER_SUCCESS",
  ADD_MACHINES_TO_PROVIDER_FAILURE: "ADD_MACHINES_TO_PROVIDER_FAILURE",
  DELETE_PROVIDER_MACHINE_REQUEST: "DELETE_PROVIDER_MACHINE_REQUEST",
  DELETE_PROVIDER_MACHINE_SUCCESS: "DELETE_PROVIDER_MACHINE_SUCCESS",
  DELETE_PROVIDER_MACHINE_FAILURE: "DELETE_PROVIDER_MACHINE_FAILURE",
  UPDATE_PROVIDER_REQUEST: "UPDATE_PROVIDER_REQUEST",
  UPDATE_PROVIDER_SUCCESS: "UPDATE_PROVIDER_SUCCESS",
  UPDATE_PROVIDER_FAILURE: "UPDATE_PROVIDER_FAILURE",
  RESET_PROVIDER: "RESET_PROVIDER",
  RESET: "RESET"
}  