import { appConstants } from "redux/constants";

const initState = {      
  error: null,    
  loading: false, 
  message: null,  
  brands: []
}

export const appReducer = (state = initState, action) => {  
  switch(action.type){ 
    case appConstants.GET_INITIAL_DATA_REQUEST:    
      return {
        ...state,
        loading: true,
      }

    case appConstants.GET_INITIAL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        brands: action.payload.brands
      }
    
    case appConstants.ADD_BRAND:
      return {
        ...state,
        brands: [action.payload.data, ...state.brands],
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_INITIAL_DATA_FAILURE:    
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case appConstants.RESET_APP:
      return {
        ...state,
        loading: false,
        error: null,
        message: null,        
      }

    case appConstants.RESET:
      return initState;

    default: return state;
  }  
}