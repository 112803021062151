import { useEffect } from "react";

import SEO from "seo/SEO";
import { ProviderPage } from "templates";
import { scrollToTop } from "utils/utilities";

export const Provider = () => {
  
  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>      
      <SEO
        title="Providers | Photonence Admin"
        description="Providers | Photonence Admin"        
      /> 
      <ProviderPage/>
    </>
  );
};

export default Provider;