import { providerConstants } from "redux/constants";

const initState = {      
  error: null,    
  loading: false, 
  message: null,  
  providers: [],
  provider: null,
  totalCount: 0,
  resultPerPage: 0,  
}

export const providerReducer = (state = initState, action) => {
  let temp;
  switch(action.type){
    case providerConstants.CREATE_PROVIDER_REQUEST:
    case providerConstants.GET_PROVIDERS_REQUEST:   
    case providerConstants.UPDATE_PROVIDER_STATUS_REQUEST: 
    case providerConstants.ADD_MACHINES_TO_PROVIDER_REQUEST:
    case providerConstants.DELETE_PROVIDER_MACHINE_REQUEST:
    case providerConstants.UPDATE_PROVIDER_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case providerConstants.GET_PROVIDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        provider: null,
      }

    case providerConstants.CREATE_PROVIDER_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        providers: [action.payload.data, ...state.providers],
        loading: false,
      }

    case providerConstants.GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: action.payload.providers,
        totalCount: action.payload.filterCount,
        resultPerPage: action.payload.resultPerPage,
        loading: false,
      }

    case providerConstants.GET_PROVIDER_DETAILS_SUCCESS:
      return {
        ...state,
        provider: action.payload,
        loading: false,
      }

    case providerConstants.UPDATE_PROVIDER_STATUS_SUCCESS:
      temp = state.providers.map(provider => {
        if(provider._id === action.payload.data._id){
          return action.payload.data
        }else{
          return provider
        }
      })
      return {
        ...state,
        providers: temp,
        message: action.payload.message,
        loading: false,
      }      

    case providerConstants.ADD_MACHINES_TO_PROVIDER_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
      }

    case providerConstants.DELETE_PROVIDER_MACHINE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
      }

    case providerConstants.UPDATE_PROVIDER_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
      }

    case providerConstants.CREATE_PROVIDER_FAILURE:
    case providerConstants.GET_PROVIDERS_FAILURE:
    case providerConstants.GET_PROVIDER_DETAILS_FAILURE:
    case providerConstants.UPDATE_PROVIDER_STATUS_FAILURE:
    case providerConstants.ADD_MACHINES_TO_PROVIDER_FAILURE:
    case providerConstants.DELETE_PROVIDER_MACHINE_FAILURE:
    case providerConstants.UPDATE_PROVIDER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case providerConstants.RESET_PROVIDER:
      return {
        ...state,
        error: null,
        loading: false,
        message: null,
      }
      
    case providerConstants.RESET:
      return initState;

    default: return state;
  }
}