import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { Layout } from "organisms";

const ProtectedRoutes = ({children}) => {

  const { userLoading, isAuthenticated } = useSelector(state => state.user);

  return (
    <>
      {!userLoading && (
        !isAuthenticated ? <Navigate to="/login" /> : <Layout>{children}</Layout>
      )}
    </>
  )
}

export default ProtectedRoutes;