import axios from "axios";
import { config } from "config";
import { appConstants } from "redux/constants";

export const getInitialData = () => {
  return async dispatch => {
    try{
      dispatch({
        type: appConstants.GET_INITIAL_DATA_REQUEST
      })

      const { data: brands } = await axios.get(
        `${config.STRAPI_URL}/brands?_sort=index&type=others`
      );
      dispatch({
        type: appConstants.GET_INITIAL_DATA_SUCCESS,
        payload: {
          brands
        }
      })
    }catch(error){
      dispatch({
        type: appConstants.GET_INITIAL_DATA_FAILURE,
        payload: error.response.data.error
      })
    }
  }
}