import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProviderDetail } from "redux/actions";

import { Text } from "atoms";
import SEO from "seo/SEO";
import { ManageProviderPage } from "templates";
import { scrollToTop } from "utils/utilities";

export const ManageProvider = () => {
  
  useEffect(() => {
    scrollToTop(window)
  }, []);

  const { id } = useParams();
  const dispatch = useDispatch();
  const { provider, loading } = useSelector(state => state.provider);

  useEffect(() => {
    if(id) {
      dispatch(getProviderDetail(id));
    }
  }, [dispatch, id]);

  return (
    <>      
      <SEO
        title="Manage Provider | Photonence Admin"
        description="Manage Provider | Photonence Admin"        
      />
      {!loading && provider?._id !== id ? <Text m="2rem" textAlign="center">Provider Not Found</Text> : <ManageProviderPage/>}       
    </>
  );
};

export default ManageProvider;